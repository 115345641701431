import request from "@/utils/request";

// 优惠券列表
export function CouponList(params) {
    return request({
        url: '/admin/base/couponList',
        method: 'get',
        params
    }
    )
}

// 使用优惠券
export function CouponUse(data) {
    return request({
        url: '/admin/base/couponUse',
        method: 'put',
        data
    }
    )
}