<template>
  <div>
    <el-card>
      <el-form ref="form" :inline="true">
        <el-form-item label="优惠码">
          <el-input
            placeholder="请输入优惠码"
            v-model="queryForm.f_code"
          ></el-input>
        </el-form-item>
        <el-form-item label="时间">
          <el-date-picker
            type="datetimerange"
            v-model="times"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="toQuery" icon="el-icon-search"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
      <el-table
        :data="couponListList"
        stripe
        class="table"
        ref="userTable.multipleSelection"
        header-cell-class-name="table-header"
        border
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>

        <el-table-column
          prop="id"
          label="ID"
          width="55"
          align="center"
        ></el-table-column>

        <el-table-column label="缩略图" align="center">
          <template slot-scope="scope">
            <div
              class="thumb_box"
              v-if="scope.row.thumb"
            >
              <img :src="scope.row.thumb_url" alt="" />
            </div>
          </template>
        </el-table-column>
        <el-table-column label="优惠码" prop="f_code" align="center"></el-table-column>
        <el-table-column label="来源" prop="source" align="center">
        </el-table-column>
        <el-table-column label="用户名" prop="user.nickname" align="center">
        </el-table-column>
        
        <el-table-column label="时间" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.create_time !== null">
              {{
                $moment(scope.row.create_time * 1000).format(
                  "YYYY-MM-DD HH:mm:ss"
                )
              }}
            </div>
            <div v-else>空</div>
          </template>
        </el-table-column>
       

        <el-table-column label="操作" align="center" >
          <template slot-scope="scope">
            <el-button type="primary" size="small" icon="el-icon-edit" circle @click="toUsed(scope.row.id)"></el-button>
            <el-button type="success" size="small" icon="el-icon-info" circle @click="toDetails(scope.row)"></el-button>
          </template>
        </el-table-column>
      </el-table> 

       <!-- 分页区域 -->
     <div class="pagination">
       <span></span>
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryForm.page"
        :page-sizes="[3, 5, 8, 10]"
        :page-size="queryForm.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        algin:="center"
      ></el-pagination>
     </div>

    <!-- 详情弹框 -->
    <el-dialog
      :visible.sync="detailsDialog"
      title="详情"
      append-to-body
      width="50%"
      :close-on-click-modal="false"
    >
      <el-form :model="detailsData" ref="details">
        <el-form-item el-form-item label="用户ID：" label-width="100px">
          {{detailsData.user_id}}
        </el-form-item>
        <el-form-item el-form-item label="用户名：" label-width="100px">
          {{detailsData.user.nickname}}
        </el-form-item>

        <el-form-item el-form-item label="缩略图：" label-width="100px">
           <div
              class="thumb_box"
              v-if="detailsData.thumb"
            >
              <img :src="detailsData.thumb_url" alt="" />
            </div>
        </el-form-item>

        <el-form-item el-form-item label="优惠码：" label-width="100px">
          {{detailsData.f_code}}
        </el-form-item>
        <el-form-item el-form-item label="来源：" label-width="100px">
          {{detailsData.source}}
        </el-form-item>
        <el-form-item el-form-item label="现金价值：" label-width="100px">
          {{detailsData.cash}}
        </el-form-item>

        <el-form-item el-form-item label="使用条件：" label-width="100px">
          {{detailsData.condition}}
        </el-form-item>

        <el-form-item el-form-item label="是否使用：" label-width="100px">
          {{detailsData.is_use==0?'未使用':'已使用'}}
        </el-form-item>

        <el-form-item v-if="detailsData.is_use==1" el-form-item label="使用时间：" label-width="100px">
          {{$moment(detailsData.use_time * 1000).format("YYYY-MM-DD HH:mm:ss")}}
        </el-form-item>

        <el-form-item el-form-item label="过期时间：" label-width="100px">
           {{$moment(detailsData.expire * 1000).format("YYYY-MM-DD HH:mm:ss")}}
        </el-form-item>
        <el-form-item el-form-item label="时间：" label-width="100px">
           {{$moment(detailsData.create_time * 1000).format("YYYY-MM-DD HH:mm:ss")}}
        </el-form-item>
      </el-form>
    </el-dialog>


     <!-- 使用优惠券 -->
      <el-dialog
        title="使用优惠券"
        :visible.sync="usedDialog"
        :center="true"
        width="40%"
        @close="closed"
        :close-on-click-modal="false"
      >
        <el-form :model="usedForm" ref="usedForm">
         
          <el-form-item el-form-item label="优惠码" label-width="100px">
            <el-input
              v-model.trim="usedForm.f_code"
              placeholder="请输入优惠码"
            ></el-input>
          </el-form-item>


          <el-form-item label-width="40%" style="margin-top: 20px">
            <el-button type="primary" @click="usedNow">立即使用</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>

    </el-card>
  </div>
</template>

<script>
import { CouponList, CouponUse } from "@/api/couponsList.js";
export default {
  data() {
    return {
      queryForm: {
        f_code: "",
        page: 1,
        limit: 10,
        start_time: null,
        end_time: null,
      },
      couponListList: [],
      total: 0,
      times: null,
      detailsDialog: false,
      detailsData: {
        user: {},
      },
      usedDialog: false,
      usedForm: {
        id: "",
        f_code: "",
      },
    };
  },

  created() {
    this.getCouponList();
  },
  methods: {
    //   点击操作栏的按钮
    toUsed(id) {
      this.usedForm.id = id;
      this.usedDialog = true;
    },
    // 使用优惠券弹框关闭事件
    closed() {
      this.usedForm = {
        id: "",
        f_code: "",
      };
    },
    // 点击立即使用优惠券
    usedNow() {
      if (this.usedForm.f_code == "") {
        this.$message.error("请输入优惠码");
        return;
      }
      CouponUse(this.usedForm).then((res) => {
        if (res.code == 1) {
          this.$message.success("使用优惠券成功");
        }
      });
    },
    //   获取优惠券列表
    getCouponList() {
      CouponList(this.queryForm).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.total = res.data.total;
        this.couponListList = res.data.data;
      });
    },
    handleSizeChange(size) {
      this.queryForm.limit = size;
      this.getCouponList();
    },
    handleCurrentChange(page) {
      this.queryForm.page = page;
      this.getCouponList();
    },

    //  查询
    toQuery() {
      this.queryForm.page = 1;
      if (this.times) {
        this.queryForm.start_time = new Date(this.times[0]).getTime() / 1000;
        this.queryForm.end_time = new Date(this.times[1]).getTime() / 1000;
      } else if (this.times == null) {
        this.queryForm.start_time = null;
        this.queryForm.end_time = null;
      }
      this.getCouponList();
    },

    // 点击详情
    toDetails(item) {
      this.detailsData = JSON.parse(JSON.stringify(item));
      console.log(this.detailsData);
      this.detailsDialog = true;
    },
  },
};
</script>

<style scoped lang="less">
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}

.table {
  width: 100%;
  font-size: 14px;
}

.red {
  color: #ff0000;
}

.mr10 {
  margin-right: 10px;
}

.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}

.add {
  position: fixed;
  /*align-self: flex-end;*/
  right: 130px;
}

.refresh {
  /* position: fixed; */
  /*align-self: flex-end;*/
  /* right: 80px; */
  float: right;
}

.edit {
  margin-left: 50px;
  width: 100px;
  margin-top: 30px;
}

.editUserItemLeft {
  padding-left: 15px;
  margin-top: 5px;
}

.editUserItem {
  margin-bottom: 10px;
  padding-left: 15px;
}
.el-tag {
  cursor: pointer;
}
.pagination {
  display: flex;
  justify-content: space-between;
}
.detail-box {
  height: 750px;
}
.detail {
  width: 100%;
  height: 80px;
  display: flex;
  margin-bottom: 30px;
}
.detail .detail-img {
  flex: 2;
}
.detail-img img {
  width: 82px;
  height: 82px;
  border-radius: 50%;
}
.detail .detail-one {
  flex: 4;
}
.detail .detail-two {
  flex: 5;
}
.detail .detail-three {
  flex: 3;
}
.detail p {
  line-height: 28px;
  color: #323232;
  font-size: 16px;
  font-weight: 500;
}
.detail-box .tabs {
  border-bottom: 2px solid #38a28a;
}
.detail-box .tabs .el-button {
  width: 160px;
  height: 50px;
  border-radius: 0;
}
.detail-box .detail-content {
  height: 504px;
  background-color: #f0f0f0;
}
.jurisdiction {
  height: 700px;
  border-top: 1px solid #ccc;
}
.jurisdiction .jurisdiction-content {
  height: 600px;
  overflow: auto;
}
.jurisdiction .jurisdiction-confirm {
  text-align: center;
  height: 100px;
  line-height: 100px;
  margin: auto;
  border-top: 1px solid #ccc;
}
.jurisdiction .jurisdiction-confirm .el-button {
  width: 90px;
  height: 40px;
}
.desc {
  width: 180px;
  height: 112px;
  overflow: hidden;
}
.desc img {
  width: 100%;
  height: 100%;
}
.generate {
  float: right;
}
.file-name {
  /* display: inline-block; */
  width: 100%;
  margin-left: 10px;
  margin-right: 20px;
  white-space: nowrap;
  overflow: hidden;
}
.imgSelect img {
  width: 300px;
  height: 200px;
  cursor: pointer;
}
.videoSelect video {
  width: 300px;
  height: 200px;
  cursor: pointer;
}
::v-deep .el-pager li.active {
  color: #ff6688;
}

.thumb_box {
  width: 100%;
  height: 100%;
  cursor: pointer;
  img {
    width: 90px;
    height: 90px;
  }
}
</style>
